@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --secondary-background-gradient: linear-gradient(
    90deg,
    #a0762f 0%,
    #dec481 51%,
    #a0762f 100%
  );
  --primary-background-gradient: linear-gradient(
    142.26deg,
    #141b35 1.22%,
    #060b22 105.61%
  );
  --font-color: #ffffff;
  --blue-color-gradient: linear-gradient(
    142.26deg,
    #141b35 1.22%,
    #060b22 105.61%
  );
}
@font-face {
  font-family: "segoeui";
  src: local("fonts"),
   url("/public/fonts/Segoe\ UI.ttf") format("truetype");
  font-weight: bold;
 }
@font-face {
  font-family: "segoeui Bold";
  src: local("fonts"),
   url("/public/fonts/Segoe\ UI\ Bold.ttf") format("truetype");
  font-weight: bold;
 }
body {
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

/* li{
  font-weight: 600 !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "segoeui Bold";
  /* font-weight: 600 !important; */
}

section {
  margin: 50px 0;
}
.listitem h4{
  font-size: 20px;
}
.commanbtn,.commanbtn2{
  font-family: "segoeui Bold " !important;
  font-weight: bold;

}
.commantitle {
  color: var(--secondary-background-gradient);
}

.itemboxprodducttext {
  color: #000;
}

.col1box h4 {
  color: #fff;
}

.titlerow {
  margin: 50px 0;
}

.titlerow2 {
  margin-top: 100px !important;
}

.sectiontitle {
  font-size: 43px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.new-color {
  background-image: -webkit-linear-gradient(
    90deg,
    #a0762f 0%,
    #dec481 51%,
    #a0762f 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box1 {
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  background: var(--primary-background-gradient);
  color: var(--secondary-background-gradient);
  margin: 10px 0;
}

.mypib {
  color: #000000;
}

.sectionib1 {
  background: transparent
    linear-gradient(90deg, #1a4dbe 0%, #4aa3ea 52%, #1a4dbe 100%);
  padding: 10px 0;
}

.servicesrow {
  margin: 100px 0 !important;
}

.box1:hover {
  transform: scale(1.05);
  background: var(--secondary-background-gradient);
  color: var(--primary-background-gradient);
}

.sevicestext {
  padding: 20px;
}

.insidebox {
  margin: 10px 0;
}

.insidebox h6 {
  font-size: 20px;
  color: #fff;
}

.centerrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.maintextsecvice h2 {
  font-size: xx-large;
  color: var(--secondary-background-gradient);
}

.comanp {
  color: #ffff;
  line-height: 25px;
}

.footersection {
  /* background: linear-gradient(90deg, hsl(38.47deg 58.22% 44.12%) 0%, hsl(47.04deg 86.21% 71.57%) 49%, hsl(38.47deg 58.22% 44.12%) 100%); */
  background-image: -webkit-linear-gradient(
    90deg,
    #a0762f 0%,
    #dec481 51%,
    #a0762f 100%
  );
  margin: 0 !important;
  padding-top: 50px;
}

.imgside img {
  width: 100%;
}

.slick-slider {
  overflow: hidden;
}

.mt5row {
  margin: 50px 0 !important;
}

.servicebtn {
  margin: 50px 0;
}

.tradingview-widget-copyright {
  visibility: hidden !important;
}

.servicescolimg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagebannerouter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ourproductnewcol a {
  text-decoration: none;
}

.footerul li {
  padding: 5px 0;
}

.footerttitle {
  letter-spacing: 1px;
}

.itemboxprodducttext h4 {
  font-size: 25px;
  letter-spacing: 1px;
}

.steptext h4 {
  font-size: 25px;
  letter-spacing: 1px;
}
.contact-padding {
  padding: 50px 0;
}
.listitem h4 {
  color: var(--font-color);
}
.steptext p {
  color: var(--font-color) !important;
}
.whyusiconboxtext p {
  color: var(--font-color);
}
.address-color {
  color: var(--blue-color-gradient);
}
.newbtn{
  background: var(--secondary-background-gradient);
  color: #000;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid var(--secondary-background-gradient);

}
.btnofmobileheader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
/* //desktop// */
@media only screen and (min-width: 1024px) {
  .listitem{
    margin-left:10px ;
  }
  .ourproductrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px !important;
  }
  .listitemlogo{
    width: 18%;
  }
  .listitemlogo img{
    width: 100%;
  }
  .mt5logoimg{
    width: 50%;
  }

  .tradebanner .commanbtn2 {
    margin: 10px 10px;
  }

  .listitemlogo {
    margin-right: 20%;
  }

  .loginicon {
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
    padding: 15px;
    border-radius: 50%;
    margin-left: 0 !important;
    border: 1px solid var(--secondary-background-gradient);
  }

  .loginicon:hover {
    background: var(--primary-background-gradient);
    color: #fff;
    padding: 15px;
    border-radius: 50%;
    transition: all 0.3s ease-in;
    border: 1px solid var(--secondary-background-gradient);
    margin-left: 0 !important;
  }

  .technicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .aftertechnicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: var(--primary-background-gradient);
    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .aftertechnicaleducationbox:hover {
    transition: all 0.6s ease;
    cursor: pointer;
  }

  .technicaleducationbox:hover {
    transition: all 0.6s ease;

    cursor: pointer;
  }

  .forbox1 {
    margin: 50px 0;
    font-size: 25px;
  }

  .technicaleducationicon {
    margin: 50px;
  }

  .stepmainbox {
    display: flex;
    flex-wrap: wrap;
  }

  .stepmainbox span {
    background: #000;
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .afterstepbox {
    color: var(--secondary-background-gradient);
    display: flex;
    flex-wrap: wrap;
  }

  .afterstepbox span {
    background: var(--secondary-background-gradient);
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .captionstep {
    margin: 20px 0;
  }

  .numberbox {
    background: var(--secondary-background-gradient);
    color: #fff;
    padding: 10px;
    margin: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .stepmainbox:hover {
    cursor: pointer;
  }

  .numbertext {
    padding: 20px 0;
  }

  .whyusbox1 {
    background: var(--primary-background-gradient);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    margin: 50px 0;
  }

  .whyusbox1:hover {
    transform: scale(1.1);
    translate: all 0.8s;
    z-index: 1;
  }

  .ourproductbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--primary-background-gradient);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 0;
  }

  .ourproductbox:hover {
    transform: scale(1.1);
  }

  .itemboxproduct {
    background: #f7e5a67d;
    padding: 10px;
    border-radius: 50%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 150px;
    height: 150px;
  }

  .whyusiconbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7e5a67d;
    padding: 20px;
    border-radius: 50%;
  }

  .imgcolib2 {
    display: flex !important;
    align-items: center !important;
    justify-content: end;
    order: 1 !important;
  }

  .iconstep {
    background: #f7e5a67d;
    padding: 20px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    text-align: center;
  }

  .step {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .edutechnicalboxrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .ubullist {
    display: flex;
    color: var(--secondary-background-gradient);
    margin-left: -30px;
  }

  .ubullist ul li {
    margin: 10px 0;
  }

  .ibbtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;
  }

  .ibbtn a {
    text-decoration: none;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
  }

  .ibbtn a:hover {
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
  }

  .ubullist ul {
    list-style: none;
  }

  .educationbox {
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 30px;
    margin: 0 30px;
    width: 300px;
    height: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .edutechnicalcol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .eduboxtext {
    padding: 40px 0;
  }

  .mt5btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 50px 0;
  }

  .mt5logoimg {
    margin: 20px 0;
  }

  .mt5btn a {
    text-decoration: none;
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
  }

  .mt5btn a:hover {
    background: #f5fafe;
    color: var(--secondary-background-gradient);
  }

  .whychoosesection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: #ffff;
    padding: 30px;
  }

  .col1 {
    margin: 50px 0;
  }

  .col1 h4 {
    color: #fff;
  }

  .whychhose {
    color: #fff;
    margin: 20px 0;
  }

  .col1box {
    margin: 100px 10px;
  }

  .col1box2 {
    margin: 100px 50px;
  }

  .Stepprocessrow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 100px;
  }

  .stepprocesscol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 50px !important;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
  }

  .stepprocesscol:hover {
    transform: scale(1.1);
  }

  .steptext {
    margin: 20px 0;
  }

  .conactformrow {
    margin: 50px 0 !important;
  }

  .contactfrom input,
  textarea {
    width: 80%;
    height: 60px;
    margin: 15px 0;
    border-radius: 6px;
    background: var(--primary-background-gradient);
    color: #fff;
  }

  .contactfrom textarea {
    height: 120px;
  }

  .contactbtn {
    margin: 20px 0;
  }

  .contactfrom input::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 30px;
  }

  .contactfrom textarea::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 30px;
    padding-top: 20px;
  }

  .acodiantitle button {
    font-size: 20px !important;
  }

  .acodianbody {
    background: #fff;
    color: var(--secondary-background-gradient);
    font-size: 15px;
  }

  .ourproductcol {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .iconimg h4 {
    margin: 20px 0;
  }

  .conactrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .contactboxtext {
    margin: 20px 0;
  }

  .pricelistrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px !important;
  }

  .pagebanner {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    margin-top: 0 !important;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagebannermain {
    padding: 125px 0;
  }

  .pagetitle {
    font-size: 80px;
    color: #ffff;
    text-transform: uppercase;
  }

  .pagebannertext {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .dwonloadnowsection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    padding: 20px;
    margin: 100px 0 !important;
  }

  .dwonloadtextside {
    margin: 50px 0;
    color: #ffff;
  }

  .dwonloadtextside h2 {
    color: #fff;
    padding-top: 30px;
  }

  .dwonloadtextside p {
    color: #fff;
  }

  .dwomalodbtn {
    display: flex;
    flex-wrap: wrap;
  }

  .dwomalodbtn a {
    margin: 10px;
  }

  .pricelist {
    border: 2px solid var(--secondary-background-gradient);

    background: #fff;
    border-radius: 20px;
    width: 350px;
    height: 550px;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .listbox ul {
    list-style: none !important;
  }

  .listbox ul li:hover {
    color: var(--secondary-background-gradient);
    transform: scale(1.1);
  }

  .headeing hr {
    color: var(--primary-background-gradient);
  }

  .listbox ul li {
    margin: 30px 0;
  }

  .pricelistcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .headeing {
    text-align: center;
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: var(--primary-background-gradient);
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
    padding: 10px;
  }

  .headeing h4 {
    font-size: 40px;
    color: var(--primary-background-gradient);
  }

  .forfootermobile {
    display: none;
  }

  .tradenowsection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    height: 300px;
    color: var(--secondary-background-gradient);
  }

  .advantagesrow {
    margin-top: 80px !important;
    display: flex;
    justify-content: center;
  }

  .techniaclstep {
    background: var(--primary-background-gradient);
    padding: 20px;
    text-align: center;
    color: #fff;
    width: 250px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    /* border-radius: 20px; */
  }

  .coladvantage {
    display: flex;
    justify-content: center;
  }

  .titletehnical h4 {
    margin: 30px 0;
  }

  .technicaltext {
    margin: 20px 0;
    color: var(--secondary-background-gradient);
  }

  .footerttitle {
    color: var(--blue-color-gradient);
    font-weight: 700;
    margin-left: 30px;
  }

  .footerul {
    list-style: none;
    text-decoration: none;
    color: #fff !important;
  }

  .tradetext {
    margin-top: 80px !important;
  }

  .tradetext h2 {
    font-size: 35px !important;
    color: var(--blue-color-gradient);
    font-weight: 700;
    letter-spacing: 2px;
  }

  .tradenow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px !important;
  }

  .myp {
    line-height: 30px;
    color: #000000;
    font-size: 20px;
    font-weight: 300;
  }

  .sliederbtn {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
  }

  .sliederbtn .commanbtn2 {
    margin: 20px 20px;
  }

  .footerul li a {
    text-decoration: none;
    color: #fff;
  }

  .footerul li i {
    color: #fff;
    margin-right: 10px;
  }

  .footerul li span {
    color: #fff;
  }

  .footerul li span:hover {
    color: #000;
  }

  .footerul li a:hover {
    color: #000;
  }

  .socialmediaul {
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .socialmediaul li {
    margin-top: 10px;
    margin-right: 20px;
    height: 30px;
    width: 30px;
    background: var(--secondary-background-gradient);
    border-radius: 50%;
    padding: 4px 0;
  }

  .footertext {
    color: #fff;
  }

  .copyright {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0 !important;
    padding: 30px 0;
  }

  .socialmediaul li:hover {
    background: #000000;
  }

  .socialmediaul li a {
    text-decoration: none;
    color: #fff;
  }

  .header-list {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    align-items: center;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 25px 0;
    border-top: 1px solid var(--secondary-background-gradient);
    border-bottom: 1px solid var(--secondary-background-gradient);
    height: auto;
    margin-bottom: 0 !important;
    position: fixed;
    width: 100%;
    height: 115px;
    z-index: 1000;
  }

  .sliderimg img {
    width: 100%;
    margin: 100px 0;
  }

  .logorowformobile {
    display: none;
  }

  .logorow {
    display: none;
  }

  .login {
    margin-right: 100px;
  }

  .commanbtn {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px 18px;
    border-radius: 50px;
    margin: 10px 0px;
    border: 2px solid var(--secondary-background-gradient);
    font-family: "segoeui";
    letter-spacing: 1px;
  }

  .commanbtn2 {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px;
    border-radius: 50px;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 10px 0;
    border: 2px solid var(--primary-background-gradient);
    font-family: "segoeui";;
    font-weight: 700;
  }

  .commanbtn:hover {
    background: var(--primary-background-gradient);
    transition: all 0.5s ease-in-out;
    color: #fff;
    border: 2px solid var(--secondary-background-gradient);
  }

  .commanbtn2:hover {
    background: var(--primary-background-gradient);

    color: var(--font-color);
    border: 2px solid var(--secondary-background-gradient);
  }

  .submenu {
    position: absolute;
    text-decoration: none;
    background: var(--secondary-background-gradient);
    width: 210px;
    margin-left: -50px;
    text-decoration: none;
    color: var(--secondary-background-gradient);
    list-style: none;
    top: 110px;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    z-index: 1;
  }

  .submenu li {
    margin: 10px 0;
    font-size: 20px;
    font-family:"segoeui bold";
    font-weight: 500 !important;
    padding: 5px;
    margin-left: 20px;
  }

  .submenu a {
    color: var(--primary-background-gradient);
    text-decoration: none;
    margin-bottom: -2px;
    font-weight: bolder;
  }

  .submenu a:hover {
    color: #f5fafe;
  }

  .slider {
    /* background: linear-gradient(90deg, hsl(38.47deg 58.22% 44.12%) 0%, hsl(47.04deg 86.21% 71.57%) 49%, hsl(38.47deg 58.22% 44.12%) 100%); */
    background-image: -webkit-linear-gradient(
      90deg,
      #a0762f 0%,
      #dec481 51%,
      #a0762f 100%
    );
    height: 500px;
    overflow: hidden;
    margin-top: 100px;
  }

  .slidertext {
    padding: 50px;
    margin-top: 80px;
  }

  .slidertext h2 {
    font-size: 45px;
    color: var(--blue-color-gradient);
  }

  .slidertext p {
    color: #f5fafe;
    line-height: 25px;
    margin: 20px 0;
  }

  .slliderbtn {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
  }
}

/* tablet */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .header-list {
    display: none;
  }
  .mt5logoimg{
    width: 100%;
  }

  .tradetext {
    margin-top: 80px !important;
  }

  .tradetext h2 {
    font-size: 35px !important;
    color: #000 !important;
    font-weight: 700;
  }

  .tradenow {
    margin: 50px 0 !important;
  }

  .technicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);

    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 20px 0;
  }

  .aftertechnicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: var(--secondary-background-gradient);
    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 20px 0;
    text-align: center;
  }

  .technicaleducationicon h4 {
    margin-left: 50px;
  }

  .aftertechnicaleducationbox:hover {
    transition: all 0.6s ease;
    cursor: pointer;
  }

  .submenu2 li {
    padding: 10px;
    list-style: none;
    color:#000;
    font-size: 20px;
    font-family: "segoeui";;
  }

  .submenu2 li :hover {
    color: #fff;
  }

  .submenu2 a {
    text-decoration: none;
  }

  .header-list2 {
    position: absolute;
    top: 80px;
  }

  .closemenu {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 15%;
    font-size: 35px;
    color: #000000;
  }

  .closemenu i {
    color: #000000 !important;
  }

  .listitem2 {
    padding: 15px;
    color: var(--secondary-background-gradient);
    list-style: none;
  }

  .mobilesidebar {
    position: fixed;
    transition: all 0.8 ease-in-out;
    background: #f5fafe;
    top: 0;
    width: 40%;
    height: 100%;
    margin-bottom: 0 !important;
    z-index: 1;
  }

  .logorowformobile {
    background: #fff;
    /* opacity: 0.6; */
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 0 !important;
    position: fixed !important;
    z-index: 1 !important;
    width: 100%;
    margin-bottom: 50px !important;
  }

  .bar {
    padding: 10px;
    font-size: 30px;
  }

  .logorow {
    display: none !important;
  }

  .stepmainbox {
    display: flex;
    flex-wrap: wrap;
  }

  .stepmainbox span {
    background: #000;
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .afterstepbox {
    color: var(--secondary-background-gradient);
    display: flex;
    flex-wrap: wrap;
  }

  .afterstepbox span {
    background: var(--secondary-background-gradient);
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .captionstep {
    margin: 20px 0;
  }

  .numberbox {
    background: var(--secondary-background-gradient);
    color: #fff;
    padding: 10px;
    margin: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .stepmainbox:hover {
    cursor: pointer;
  }

  .numbertext {
    padding: 20px 0;
  }

  .ourproductbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--primary-background-gradient);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 0;
  }

  .ourproductbox:hover {
    transform: scale(1.1);
  }

  .itemboxproduct {
    background: #f5fafe;
    padding: 10px;
    border-radius: 50%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 150px;
    height: 150px;
  }

  .whyusbox1 {
    background: var(--primary-background-gradient);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    margin: 50px 0;
  }

  .whyusbox1:hover {
    transform: scale(1.1);
    translate: all 0.8s;
    z-index: 1;
  }

  .whyusiconbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5fafe;
    padding: 20px;
    border-radius: 50%;
  }

  .ubullist {
    display: flex;
    color: var(--secondary-background-gradient);
    margin-left: -30px;
  }

  .ubullist ul li {
    margin: 10px 0;
    list-style: none;
  }

  .ibbtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 0;
  }

  .ibbtn a {
    text-decoration: none;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
  }

  .ibbtn a:hover {
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
  }

  .edutechnicalboxrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .educationbox {
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 30px;
    margin: 10px 30px;
    width: 300px;
    height: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .edutechnicalcol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .imgcolib {
    display: flex;
    align-items: center;
  }

  .eduboxtext {
    padding: 40px 0;
  }

  .mt5btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 50px 0;
  }

  .mt5logoimg {
    margin: 20px 0;
  }

  .mt5btn a {
    text-decoration: none;
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
  }

  .mt5btn a:hover {
    background: #f5fafe;
    color: var(--secondary-background-gradient);
  }

  .whychoosesection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: #ffff;
    padding: 30px;
  }

  .col1 {
    margin: 50px 0;
    text-align: center;
  }

  .col1 h4 {
    color: #fff;
  }

  .whychhose {
    color: #fff;
    margin: 20px 0;
  }

  .col1box {
    margin: 100px 10px;
  }

  .col1box2 {
    margin: 100px 50px;
  }

  .Stepprocessrow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .stepprocesscol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 50px !important;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0 0 0 0;
  }

  .stepprocesscol:hover {
    transform: scale(1.1);
  }

  .steptext {
    margin: 20px 0;
  }

  .conactformrow {
    margin: 50px 0 !important;
  }

  .contactfrom input,
  textarea {
    width: 80%;
    height: 60px;
    margin: 15px 0;
    border-radius: 6px;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
  }

  .contactfrom textarea {
    height: 120px;
  }

  .contactbtn {
    margin: 20px 0;
  }

  .contactfrom input::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 30px;
  }

  .contactfrom textarea::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 30px;
    padding-top: 20px;
  }

  .conactrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .contactboxtext {
    margin: 20px 0;
  }

  .ourproductrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px !important;
  }

  .ourproductcol {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 10px 0;
  }

  .iconimg h4 {
    margin: 20px 0;
  }

  .pagebanner {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    margin-top: 0 !important;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagebannermain {
    padding: 125px 0;
  }

  .pagetitle {
    font-size: 80px;
    color: #fff;
    text-transform: uppercase;
  }

  .pagebannertext {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .dwonloadnowsection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    padding: 20px;
    margin: 100px 0 !important;
  }

  .dwonloadtextside {
    margin: 50px 0;
    color: #ffff;
  }

  .dwonloadtextside h2 {
    color: #fff;

    padding-top: 30px;
  }

  .dwonloadtextside p {
    color: #fff;
  }

  .dwomalodbtn {
    display: flex;
    flex-wrap: wrap;
  }

  .dwonloadimg img {
    margin-top: 50px;
    width: 100% !important;
  }

  .dwomalodbtn a {
    margin: 10px;
  }

  .pricelistrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px !important;
  }

  .pricelist {
    border: 2px solid var(--secondary-background-gradient);

    background: #fff;
    border-radius: 20px;
    width: 350px;
    height: 550px;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .listbox ul {
    list-style: none !important;
  }

  .listbox ul li:hover {
    color: var(--secondary-background-gradient);
    transform: scale(1.1);
  }

  .headeing hr {
    color: var(--secondary-background-gradient);
  }

  .listbox ul li {
    margin: 30px 0;
  }

  .pricelistcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .headeing {
    text-align: center;
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: var(--primary-background-gradient);
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
    padding: 10px;
  }

  .headeing h4 {
    font-size: 40px;
    color: var(--secondary-background-gradient);
  }

  .tradenowsection {
    background:var(--secondary-background-gradient);
    height: 330px;
    color: var(--secondary-background-gradient);
  }

  .tradetext {
    margin-top: 80px !important;
  }

  .tradetext h2 {
    font-size: 35px !important;
    color: #000000 !important;
  }

  .tradenow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px !important;
  }

  .advantagesrow {
    margin-top: 80px !important;
    display: flex;
    justify-content: center;
  }

  .techniaclstep {
    background: var(--primary-background-gradient);
    padding: 20px;
    text-align: center;
    color: #fff;
    width: 250px;
    height: 200px;
    margin: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    /* border-radius: 20px; */
  }

  .coladvantage {
    display: flex;
    justify-content: center;
  }

  .titletehnical h4 {
    margin: 30px 0;
  }

  .technicaltext {
    margin: 20px 0;
    color: var(--secondary-background-gradient);
  }

  .maintextsecvice h2 {
    font-size: 30px;
    color: var(--secondary-background-gradient);
  }

  .myp {
    line-height: 25px;
    color: #000000;
    font-size: 20px;
    font-weight: 300;
  }

  .footerttitle {
    color: var(--blue-color-gradient);
    font-weight: 700;
    margin-left: 30px;
  }

  .footerul {
    list-style: none;
    text-decoration: none;
  }

  .footerul li a {
    text-decoration: none;
    color: #ffff;
  }

  .footerul li i {
    color: var(--secondary-background-gradient);
    margin-right: 10px;
  }

  .footerul li span {
    color: #fff;
  }

  .footerul li span:hover {
    color: #000;
  }

  .footerul li a:hover {
    color: #000;
  }

  .socialmediaul {
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .socialmediaul li {
    margin-top: 10px;
    margin-right: 20px;
    height: 30px;
    width: 30px;
    background: var(--secondary-background-gradient);
    border-radius: 50%;
    padding: 4px 0;
  }

  .seviceimg {
    margin: 100px 0;
  }

  .footertext {
    color: #fff;
  }

  .copyright {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0 !important;
    padding: 30px 0;
  }

  .socialmediaul li:hover {
    background: var(--primary-background-gradient);
  }

  .socialmediaul li a {
    text-decoration: none;
    color: #fff;
  }

  .forfootermobile {
    display: none;
  }

  .slidertext {
    padding: 25px !important;
    margin-top: 50px !important;
  }

  .slidertext h2 {
    font-size: 25px;
    color: var(--blue-color-gradient);
  }

  .slidertext p {
    color: #fff;
    line-height: 25px;
  }

  .slliderbtn {
    margin: 50px 0;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .slliderbtn .commanbtn {
    margin: 60px 5px;
  }

  .slider {
    /* background: linear-gradient(90deg, hsl(38.47deg 58.22% 44.12%) 0%, hsl(47.04deg 86.21% 71.57%) 49%, hsl(38.47deg 58.22% 44.12%) 100%); */
    background-image: -webkit-linear-gradient(
      90deg,
      #a0762f 0%,
      #dec481 51%,
      #a0762f 100%
    );
    height: 470px;
    overflow: hidden;
    margin-top: 76px;
  }

  .sliderimg img {
    width: 100%;
    margin: 120px 0;
  }

  .logorow {
    display: flex;
    background: var(--primary-background-gradient);
    padding: 40px 0;
    justify-content: space-between;
  }

  .login {
    margin-right: 50px;
  }

  .commanbtn {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 20px;
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn2 {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 0;
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn:hover {
    background-color: var(--primary-background-gradient);

    color: var(--secondary-background-gradient);
    border: 2px solid var(--secondary-background-gradient);
  }

  .commanbtn2:hover {
    background-color: var(--primary-background-gradient);

    color: var(--secondary-background-gradient);
    border: 2px solid var(--secondary-background-gradient);
  }

  .submenu {
    position: absolute;
    text-decoration: none;
    background: var(--secondary-background-gradient);
    width: 150px;
    margin-left: -50px;
    text-decoration: none;
    color: var(--secondary-background-gradient);
    list-style: none;
    top: 210px;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    z-index: 1;
  }

  .submenu li {
    margin: 10px 0;
    font-size: 20px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500 !important;
  }

  .submenu li :hover {
    color: #f5fafe;
  }

  .submenu a {
    color: var(--primary-background-gradient);
    text-decoration: none;
  }

  .submenu a:hover {
    color: #f5fafe;
  }

  .sliderbtn {
    margin: 38px 0;
  }
}

/* mobile   */
@media only screen and (max-width: 767px) {
  .logorow {
    display: none;
  }
  .mt5logoimg{
    width: 100%;
  }
  .mobilelogo{
    width: 100%;
  }

.listitemlogo img{
  width: 50%;
}
  .technicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);

    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 20px 0;
  }

  .aftertechnicaleducationbox {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    background: var(--secondary-background-gradient);
    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 20px 0;
    text-align: center;
  }

  .technicaleducationicon h4 {
    margin-left: 50px;
  }

  .aftertechnicaleducationbox:hover {
    transition: all 0.6s ease;
    cursor: pointer;
  }

  .howtopenaccountsection {
    display: none;
  }

  .imageshowtoopen {
    display: none;
  }

  .stepmainbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stepmainbox span {
    background: #000;
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .afterstepbox {
    color: var(--secondary-background-gradient);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .afterstepbox span {
    background: var(--secondary-background-gradient);
    color: #ffff;
    padding: 10px;
    border-radius: 50%;
  }

  .captionstep {
    margin: 20px 0;
  }

  .numberbox {
    background: var(--secondary-background-gradient);
    color: #fff;
    padding: 10px;
    margin: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .stepmainbox:hover {
    cursor: pointer;
  }

  .numbertext {
    padding: 20px 0;
  }

  .ourproductbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--primary-background-gradient);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    border-radius: 20px;
    margin: 30px 0;
  }

  .ourproductbox:hover {
    transform: scale(1.1);
  }

  .itemboxproduct {
    background: #f5fafe;
    padding: 10px;
    border-radius: 50%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 150px;
    height: 150px;
  }

  .whyusbox1 {
    background: var(--primary-background-gradient);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    margin: 50px 0;
  }

  .whyusbox1:hover {
    transform: scale(1.1);
    translate: all 0.8s;
    z-index: 1;
  }

  .whyusiconbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5fafe;
    padding: 20px;
    border-radius: 50%;
  }

  .ubullist {
    display: flex;
    color: var(--secondary-background-gradient);
    margin-left: -30px;
    justify-content: center;
  }

  .ubullist ul li {
    margin: 10px 0;
  }

  .ibbtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
  }

  .ibbtn a {
    text-decoration: none;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
  }

  .ibbtn a:hover {
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
  }

  .edutechnicalboxrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .educationbox {
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 30px;
    margin: 10px 30px;
    width: 300px;
    height: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .edutechnicalcol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .eduboxtext {
    padding: 40px 0;
  }

  .mt5btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0;
  }

  .mt5logoimg {
    margin: 20px 0;
  }

  .mt5btn a {
    text-decoration: none;
    background: var(--secondary-background-gradient);
    color: var(--primary-background-gradient);
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 10px;
  }

  .maintextsecvice {
    text-align: center;
  }

  .mt5btn a:hover {
    background: #f5fafe;
    color: var(--secondary-background-gradient);
  }

  .whychoosesection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: #ffff;
    padding: 30px;
  }

  .col1 {
    margin: 50px 0;
    text-align: center;
  }

  .col1 h4 {
    color: #fff;
  }

  .whychhose {
    color: #fff;
    margin: 20px 0;
  }

  .col1box {
    margin: 100px 10px;
  }

  .col1box2 {
    margin: 100px 50px;
  }

  .Stepprocessrow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 100px;
  }

  .stepprocesscol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 50px !important;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0 0 0 0;
  }

  .stepprocesscol:hover {
    transform: scale(1.1);
  }

  .steptext {
    margin: 20px 0;
  }

  .conactformrow {
    margin: 50px 0 !important;
  }

  .contactfrom input,
  textarea {
    width: 80%;
    height: 60px;
    margin: 15px 0;
    border-radius: 6px;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
  }

  .contactfrom textarea {
    height: 120px;
  }

  .contactbtn {
    margin: 20px 0;
  }

  .contactfrom input::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 20px !important;
  }

  .contactfrom textarea::placeholder {
    color: var(--secondary-background-gradient);
    padding-left: 20px;
    padding-top: 20px;
  }

  .conactrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contactcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .contactboxtext {
    margin: 20px 0;
  }

  .ourproductrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px !important;
  }

  .ourproductcol {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .iconimg h4 {
    margin: 20px 0;
  }

  .pagebanner {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    margin-top: 0 !important;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagebannermain {
    padding: 125px 0;
  }

  .pagetitle {
    font-size: 50px;
    color: #ffff;
    text-transform: uppercase;
  }

  .pagebannertext {
    color: #fff;
    font-weight: 500;
  }

  .dwonloadnowsection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    padding: 20px;
    margin: 100px 0 !important;
  }

  .dwonloadtextside {
    margin: 50px 0;
    color: #ffff;
    text-align: center;
  }

  .dwonloadtextside h2 {
    color: #fff;
    padding-top: 30px;
  }

  .dwonloadtextside p {
    color: #fff;
  }

  .dwomalodbtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .dwomloadcol {
    order: -1;
  }

  .dwonloadimg img {
    margin-top: 50px;
    width: 100% !important;
  }

  .dwomalodbtn a {
    margin: 10px;
  }

  .pricelistrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px !important;
  }

  .pricelist {
    border: 2px solid var(--secondary-background-gradient);

    background: #ffff;
    border-radius: 20px;
    width: 350px;
    height: 550px;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .listbox ul {
    list-style: none !important;
  }

  .listbox ul li:hover {
    color: var(--secondary-background-gradient);
    transform: scale(1.1);
  }

  .headeing hr {
    color: var(--secondary-background-gradient);
  }

  .listbox ul li {
    margin: 30px 0;
  }

  .pricelistcol {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  .headeing {
    text-align: center;
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    color: var(--primary-background-gradient);
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
    padding: 10px;
  }

  .headeing h4 {
    font-size: 40px;
    color: var(--secondary-background-gradient);
  }

  .servicesrow {
    text-align: center;
  }

  .tradenowsection {
    background: linear-gradient(90deg, #a0762f 0%, #dec481 51%, #a0762f 100%);
    height: 500px;
    color: var(--secondary-background-gradient);
  }

  .tradetext {
    margin-top: 80px !important;
  }

  .tradetext h2 {
    font-size: 35px !important;
    color: #000000;
  }

  .tradenow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px !important;
  }

  .advantagesrow {
    margin-top: 80px !important;
    display: flex;
    justify-content: center;
  }

  .techniaclstep {
    background: var(--primary-background-gradient);
    padding: 20px;
    text-align: center;
    color: #fff;
    width: 250px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px 0;
    /* border-radius: 20px; */
  }

  .coladvantage {
    display: flex;
    justify-content: center;
  }

  .titletehnical h4 {
    margin: 30px 0;
  }

  .technicaltext {
    margin: 20px 0;
    color: var(--secondary-background-gradient);
  }

  .tradebanner {
    margin: 15px 12px;
  }

  .imgcol1 {
    order: -1;
    margin: 50px 0;
  }

  .footerul {
    color: white;
    list-style: none;
    margin-left: 0 !important;
  }

  .footerul li {
    margin: 10px 0;
  }

  .footerul li a {
    color: #000000;
    text-decoration: none;
  }

  .socialmediaul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-evenly;
  }

  .socialmediaul li {
    height: 30px;
    width: 30px;
    margin-top: 10px;
    background: var(--secondary-background-gradient);
    border-radius: 50%;
  }

  .socialmediaul li a {
    color: #000000;
    text-decoration: none;
  }

  .footertext {
    color: #fff;
  }

  .copyright {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .socialmediaul li i {
    padding-top: 6px;
  }

  .footrrow {
    display: none !important;
  }

  .footerttitle {
    color: var(--blue-color-gradient);
    font-weight: 700;
    margin: 10px 5px;
  }

  .forfootermobile {
    padding: 10px !important;
  }

  .header-list {
    display: none;
  }

  .imgcol {
    order: -1 !important;
    margin: 40px 0;
  }

  .slidertext {
    padding: 0 !important;
    text-align: center;
  }

  .sliderbtn {
    margin: 30px 0;
  }

  .slidertext h2 {
    font-size: 30px;
    color: var(--blue-color-gradient);
  }

  .slider {
    height: auto !important;
    margin-top: 65px;
  }

  .slidertext p {
    color: #f5fafe;
    line-height: 25px;
  }

  .slliderbtn {
    margin: 50px 0;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .slliderbtn .btnslider {
    margin: 20px 0;
    text-align: center;
  }

  .slider {
    /* background: linear-gradient(90deg, hsl(38.47deg 58.22% 44.12%) 0%, hsl(47.04deg 86.21% 71.57%) 49%, hsl(38.47deg 58.22% 44.12%) 100%); */
    background-image: -webkit-linear-gradient(
      90deg,
      #a0762f 0%,
      #dec481 51%,
      #a0762f 100%
    );
    height: auto;
    overflow: hidden;
    margin-top: 60px;
    padding-top: 50px;
    height: 700px !important;
  }

  .sliderimg img {
    width: 100%;
  }

  .mobilesidebar {
    position: fixed;
    transition: all 0.8 ease-in-out;
    background: #f5fafe;
    top: 0;
    width: 80%;
    height: 100%;
    margin-bottom: 0 !important;
    z-index: 1;
  }

  .header-list2 {
    position: absolute;
    top: 80px;
  }

  .closemenu {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 15%;
    font-size: 35px;
    color: #000000;
  }

  .closemenu i {
    color: #000000 !important;
  }

  .listitem2 {
    padding: 15px;
    color: var(--secondary-background-gradient);
    list-style: none;
  }

  .logorowformobile {
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    position: fixed;
    z-index: 1;
    width: 100%;
  }

  .forfootermobile {
    display: block;
  }

  .submenu2 li {
    padding: 10px;
    list-style: none;
    color: #000;
    font-size: 20px;
    font-family: "segoeui";;
  }

  .submenu2 li :hover {
    color: #fff;
  }

  .submenu2 a {
    text-decoration: none;
  }

  .bar {
    padding: 10px;
    font-size: 30px;
  }

  .commanbtn {
    background: linear-gradient(
      90deg,
      #a0762f 0%,
      #dec481 51%,
      #a0762f 100%
    );
    text-decoration: none;
    color: var(--secondary-background-gradient);
    padding: 10px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 20px;
    border: 2px solid var(--secondary-background-gradient);
  }

  .commanbtn2 {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 10px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 0;
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn:hover {
    background-color: var(--secondary-background-gradient);

    color: var(--primary-background-gradient);
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn2:hover {
    background-color: var(--primary-background-gradient);

    color: var(--secondary-background-gradient);
    border: 2px solid var(--secondary-background-gradient);
  }
}

/* 1024  */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .dnonein1024{
    display: none ;
  }
  .header-list {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    background: var(--primary-background-gradient);
    color: var(--secondary-background-gradient);
    padding: 25px 0;
    border-top: 1px solid var(--secondary-background-gradient);
    border-bottom: 1px solid var(--secondary-background-gradient);
    height: 115px;
  }

  .sliederbtn {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  .sliederbtn .sliderbtn {
    margin: 20px 20px !important;
  }

  .listitemloginicon {
    display: none;
  }
  .listitemlogo img{
    width: 100% ;
  }
  
  .listitemlogo{
    margin-right: 0 !important;
    width: 20%;
  }

  .dwonloadimg img {
    margin-top: 80px;
  }

  .advantagesrow {
    margin-top: 80px !important;
    display: flex;
    justify-content: center;
  }

  .techniaclstep {
    background: var(--primary-background-gradient);
    padding: 20px;
    text-align: center;
    color: #fff;
    width: 250px;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    /* border-radius: 20px; */
  }

  .coladvantage {
    display: flex;
    justify-content: center;
  }

  .titletehnical h4 {
    margin: 35px 0;
  }

  .technicaltext {
    margin: 20px 0;
    color: var(--secondary-background-gradient);
  }

  .seviceimg {
    margin: 50px 0;
  }

  .forfootermobile {
    display: none;
  }

  .slidertext {
    padding: 30px;
    margin-top: 100px !important;
  }

  .slidertext h2 {
    font-size: 30px;
    color: var(--blue-color-gradient);
  }

  .slidertext p {
    color: #f5fafe;
    line-height: 25px;
  }

  .slliderbtn {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .slider {
    /* background: linear-gradient(90deg, hsl(38.47deg 58.22% 44.12%) 0%, hsl(47.04deg 86.21% 71.57%) 49%, hsl(38.47deg 58.22% 44.12%) 100%); */
    background-image: -webkit-linear-gradient(
      90deg,
      #a0762f 0%,
      #dec481 51%,
      #a0762f 100%
    );
    height: 500px;
    overflow: hidden;
  }

  .sliderimg img {
    width: 100%;
    margin: 125px 0;
  }

  .logorowformobile {
    display: none;
  }

  .logorow {
    display: none;
  }

  .login {
    margin-right: 100px;
  }

  .commanbtn {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 20px;
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn2 {
    background: var(--secondary-background-gradient);
    text-decoration: none;
    color: var(--primary-background-gradient);
    padding: 15px;
    font-family: "segoeui";;
    border-radius: 50px;
    margin: 10px 0;
    border: 2px solid var(--primary-background-gradient);
  }

  .commanbtn:hover {
    background-color: var(--primary-background-gradient);

    color: var(--secondary-background-gradient);
    border: 2px solid var(--secondary-background-gradient);
  }

  .commanbtn2:hover {
    background-color: var(--primary-background-gradient);

    color: var(--secondary-background-gradient);
    border: 2px solid var(--secondary-background-gradient);
  }

  .listitemlogo {
    margin-right: 10%;
  }

  .submenu {
    position: absolute;
    text-decoration: none;
    background: var(--secondary-background-gradient);
    width: 200px;
    margin-left: -50px;
    text-decoration: none;
    color: var(--secondary-background-gradient);
    list-style: none;
    top: 112px;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    z-index: 1;
  }

  .submenu li {
    margin: 10px 0;
    font-size: 20px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500 !important;
    margin-left: 20px;
  }

  .submenu li :hover {
    color: #fff;
  }

  .submenu a {
    color: var(--primary-background-gradient);
    text-decoration: none;
  }

  .submenu a:hover {
    color: #fff;
  }
}
